"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
function TickCircleIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 20",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.5001 18.9596H7.50008C2.97508 18.9596 1.04175 17.0263 1.04175 12.5013V7.5013C1.04175 2.9763 2.97508 1.04297 7.50008 1.04297H12.5001C17.0251 1.04297 18.9584 2.9763 18.9584 7.5013V12.5013C18.9584 17.0263 17.0251 18.9596 12.5001 18.9596ZM7.50008 2.29297C3.65841 2.29297 2.29175 3.65964 2.29175 7.5013V12.5013C2.29175 16.343 3.65841 17.7096 7.50008 17.7096H12.5001C16.3417 17.7096 17.7084 16.343 17.7084 12.5013V7.5013C17.7084 3.65964 16.3417 2.29297 12.5001 2.29297H7.50008Z"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M8.8167 12.9846C8.65003 12.9846 8.4917 12.918 8.37503 12.8013L6.0167 10.443C5.77503 10.2013 5.77503 9.8013 6.0167 9.55964C6.25837 9.31797 6.65837 9.31797 6.90003 9.55964L8.8167 11.4763L13.1 7.19297C13.3417 6.9513 13.7417 6.9513 13.9834 7.19297C14.225 7.43464 14.225 7.83463 13.9834 8.0763L9.25837 12.8013C9.1417 12.918 8.98337 12.9846 8.8167 12.9846Z"
  }));
}


exports.default = TickCircleIcon;

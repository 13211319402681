"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../components/svgicon');
var _theme = require('../theme');
var _styledcomponents = require('styled-components');
function SuccessOutlineIcon(_ref) {
  let {
    size,
    color,
    ...props
  } = _ref;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 20 20",
    sx: {
      fontSize: size
    }
  }, props), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z",
    stroke: _theme.colors[mode].semantic.success.darker,
    strokeWidth: "1.33333",
    strokeMiterlimit: "10",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fill: "none"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M12.5 8.33334L9.16667 11.6667L7.5 10",
    stroke: _theme.colors[mode].semantic.success.darker,
    strokeWidth: "1.33333",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    fill: "none"
  }));
}


exports.default = SuccessOutlineIcon;

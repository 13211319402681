"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _createStarExport(obj) { Object.keys(obj) .filter((key) => key !== "default" && key !== "__esModule") .forEach((key) => { if (exports.hasOwnProperty(key)) { return; } Object.defineProperty(exports, key, {enumerable: true, configurable: true, get: () => obj[key]}); }); }var _tokens = require('./tokens'); _createStarExport(_tokens);
var _positions = require('./positions'); _createStarExport(_positions);
var _pairs = require('./pairs'); _createStarExport(_pairs);
var _responses = require('./responses'); _createStarExport(_responses);
var _transactions = require('./transactions'); _createStarExport(_transactions);
var _contracts = require('./contracts'); _createStarExport(_contracts);
var _yield = require('./yield'); _createStarExport(_yield);
var _aggregator = require('./aggregator'); _createStarExport(_aggregator);
var _sdk = require('./sdk'); _createStarExport(_sdk);
var _campaigns = require('./campaigns'); _createStarExport(_campaigns);
var _account = require('./account'); _createStarExport(_account);
var _contactList = require('./contactList'); _createStarExport(_contactList);
var _accountLabels = require('./accountLabels'); _createStarExport(_accountLabels);
var _accountHistory = require('./accountHistory'); _createStarExport(_accountHistory);
var _providerInfo = require('./providerInfo'); _createStarExport(_providerInfo);
var _earn = require('./earn'); _createStarExport(_earn);
var _tiers = require('./tiers'); _createStarExport(_tiers);
var AllowanceType = /* @__PURE__ */ ((AllowanceType2) => {
  AllowanceType2["specific"] = "specific";
  AllowanceType2["max"] = "max";
  return AllowanceType2;
})(AllowanceType || {});
var SignStatus = /* @__PURE__ */ ((SignStatus2) => {
  SignStatus2["none"] = "none";
  SignStatus2["signed"] = "signed";
  SignStatus2["failed"] = "failed";
  return SignStatus2;
})(SignStatus || {});



exports.AllowanceType = AllowanceType; exports.SignStatus = SignStatus;

"use strict";Object.defineProperty(exports, "__esModule", {value: true});const baseColors = {
  black: "#000",
  white: "#FFF",
  violet: {
    violet100: "#fbfaff",
    violet200: "#e9e5ff",
    violet300: "#d0ccff",
    violet400: "#a399ff",
    violet500: "#791aff",
    violet600: "#430099",
    violet700: "#270c51",
    violet800: "#160033",
    violet900: "#0c001a"
  },
  aqua: {
    aqua100: "#f0fffb",
    aqua200: "#cdfef2",
    aqua300: "#9cfce5",
    aqua400: "#6bfad7",
    aqua500: "#07f8bd",
    aqua600: "#07dfaa",
    aqua700: "#049571",
    aqua800: "#024a39",
    aqua900: "#011913"
  },
  greyscale: {
    greyscale0: "#ffffff",
    greyscale1: "#fcfaff",
    greyscale2: "#f9f7fd",
    greyscale3: "#efecf3",
    greyscale4: "#d8cfe5",
    greyscale5: "#ccc3d9",
    greyscale6: "#bfb7cb",
    greyscale7: "#877c97",
    greyscale8: "#4a3a61",
    greyscale9: "#312049"
  },
  semantic: {
    green: {
      green100: "#e6fef2",
      green200: "#b5fcd7",
      green300: "#84fbbc",
      green400: "#53f9a1",
      green500: "#21f786",
      green600: "#08de6c",
      green700: "#06ac54",
      green800: "#047b3c",
      green900: "#01190c"
    },
    red: {
      red100: "#fee7e7",
      red200: "#fcb6b6",
      red300: "#fa8585",
      red400: "#f85454",
      red500: "#f52323",
      red600: "#dc0a0a",
      red700: "#ab0707",
      red800: "#7a0505",
      red900: "#490303",
      red1000: "#180101"
    },
    yellow: {
      yellow100: "#fef9e7",
      yellow200: "#fceeb6",
      yellow300: "#fae285",
      yellow400: "#f8d754",
      yellow500: "#f5cb23",
      yellow600: "#dcb20a",
      yellow700: "#ab8a07",
      yellow800: "#7a6305",
      yellow900: "#493b03",
      yellow1000: "#181401"
    }
  },
  disabledText: "rgba(255, 255, 255, 0.5)",
  overlay: "rgba(0, 0, 0, 0.5)",
  backgroundGrid: "#fcfaff",
  dropShadow: {
    dropShadow100: "0px 1px 2px 0px rgba(45, 12, 95, 0.05), 0px 3px 3px 0px rgba(45, 12, 95, 0.04), 0px 7px 4px 0px rgba(45, 12, 95, 0.03), 0px 13px 5px 0px rgba(45, 12, 95, 0.01), 0px 20px 6px 0px rgba(45, 12, 95, 0.00)",
    dropShadow200: "0px 2px 5px 0px rgba(45, 12, 95, 0.08), 0px 9px 9px 0px rgba(45, 12, 95, 0.07), 0px 20px 12px 0px rgba(45, 12, 95, 0.04), 0px 35px 14px 0px rgba(45, 12, 95, 0.01), 0px 55px 15px 0px rgba(45, 12, 95, 0.00)",
    dropShadow300: "0px 3px 7px 0px rgba(45, 12, 95, 0.12), 0px 13px 13px 0px rgba(45, 12, 95, 0.10), 0px 30px 18px 0px rgba(45, 12, 95, 0.06), 0px 53px 21px 0px rgba(45, 12, 95, 0.02), 0px 83px 23px 0px rgba(45, 12, 95, 0.00)",
    dropShadow400: "0px 8px 18px 0px rgba(45, 12, 95, 0.09), 0px 34px 34px 0px rgba(45, 12, 95, 0.08), 0px 75px 45px 0px rgba(45, 12, 95, 0.05), 0px 134px 54px 0px rgba(45, 12, 95, 0.01), 0px 209px 59px 0px rgba(45, 12, 95, 0.00)"
  }
};
const colors = {
  dark: {
    semantic: {
      success: {
        primary: "#149953",
        darker: "#128749",
        light: "#13bf64"
      },
      warning: {
        primary: "#e5be21",
        darker: "#cca509",
        light: "#f2d252"
      },
      error: {
        primary: "#e52121",
        darker: "#b20707",
        light: "#f55353",
        red800: "#7A0505"
      },
      informative: {
        primary: "#167ae5",
        darker: "#0a76eb",
        light: "#529ef2"
      }
    },
    semanticBackground: {
      success: "rgba(83, 249, 161, 0.1)",
      warning: "rgba(73, 59, 3, 0.1)",
      error: "rgba(252, 85, 85, 0.1)"
    },
    background: {
      primary: "#181122",
      secondary: "#1f162d",
      tertiary: "#2b1e3e",
      quartery: "rgba(24, 17, 34, 0.3000)",
      quarteryNoAlpha: "#181122",
      emphasis: "#2B1E3E",
      modals: "#1F162D"
    },
    aqua: {
      aqua100: baseColors.aqua.aqua900,
      aqua200: baseColors.aqua.aqua800,
      aqua300: baseColors.aqua.aqua700,
      aqua400: baseColors.aqua.aqua600,
      aqua500: baseColors.aqua.aqua500,
      aqua600: baseColors.aqua.aqua400,
      aqua700: baseColors.aqua.aqua300,
      aqua800: baseColors.aqua.aqua200,
      aqua900: baseColors.aqua.aqua100
    },
    violet: {
      violet100: baseColors.violet.violet900,
      violet200: baseColors.violet.violet800,
      violet300: baseColors.violet.violet700,
      violet400: baseColors.violet.violet600,
      violet500: baseColors.violet.violet500,
      violet600: baseColors.violet.violet400,
      violet700: baseColors.violet.violet300,
      violet800: baseColors.violet.violet200,
      violet900: baseColors.violet.violet100
    },
    typography: {
      typo1: "#e9dff7",
      typo2: "#bfb7cb",
      typo3: "#978ba9",
      white: "#f8f8f8",
      typo4: "#6b6278",
      typo5: "#574f61"
    },
    border: {
      border1: "#3c2956",
      border2: "#37264f",
      accent: baseColors.violet.violet500,
      accent2: baseColors.aqua.aqua500,
      border3: "rgba(40, 28, 57, 0.5000)"
    },
    accentPrimary: baseColors.aqua.aqua500,
    accent: {
      primary: baseColors.aqua.aqua500,
      accent600: baseColors.aqua.aqua700,
      accent400: baseColors.aqua.aqua400,
      accent200: baseColors.aqua.aqua900,
      accent100: baseColors.aqua.aqua900,
      primaryEmphasis: "#2b1e3f",
      secondary: baseColors.aqua.aqua500,
      secondaryEmphasis: "#024a39"
    },
    sematicWarning: "#FFD11A",
    dropShadow: {
      dropShadow100: baseColors.dropShadow.dropShadow100,
      dropShadow200: baseColors.dropShadow.dropShadow200,
      dropShadow300: baseColors.dropShadow.dropShadow300,
      dropShadow400: baseColors.dropShadow.dropShadow400
    },
    earnWizard: {
      mask: "#56FAD2",
      border: "#331066"
    },
    donutShape: {
      mask: "#56FAD2"
    },
    promoted: {
      start: baseColors.aqua.aqua600,
      stop: "#3c2956"
    }
  },
  light: {
    semantic: {
      success: {
        primary: "#1abf68",
        darker: "#11a657",
        light: "#16d971"
      },
      warning: {
        primary: "#f5cb23",
        darker: "#dcb20a",
        light: "#f8d754"
      },
      error: {
        primary: "#f52323",
        darker: "#ab0707",
        light: "#f85454",
        red800: "#7A0505"
      },
      informative: {
        primary: "#1882f5",
        darker: "#0756ab",
        light: "#54a2f8"
      }
    },
    semanticBackground: {
      success: "rgba(6, 172, 84, 0.1)",
      warning: "rgba(248, 215, 84, 0.1)",
      error: "rgba(171, 7, 7, 0.1)"
    },
    background: {
      primary: "#ebe4f5",
      secondary: "#f4f2f7",
      tertiary: "#f9f7fc",
      quartery: "rgba(244, 242, 247, 0.4000)",
      quarteryNoAlpha: "#eeeaf6",
      emphasis: "#edebf0",
      modals: "#F9F7FC"
    },
    aqua: {
      aqua100: baseColors.aqua.aqua100,
      aqua200: baseColors.aqua.aqua200,
      aqua300: baseColors.aqua.aqua300,
      aqua400: baseColors.aqua.aqua400,
      aqua500: baseColors.aqua.aqua500,
      aqua600: baseColors.aqua.aqua600,
      aqua700: baseColors.aqua.aqua700,
      aqua800: baseColors.aqua.aqua800,
      aqua900: baseColors.aqua.aqua900
    },
    violet: {
      violet100: baseColors.violet.violet100,
      violet200: baseColors.violet.violet200,
      violet300: baseColors.violet.violet300,
      violet400: baseColors.violet.violet400,
      violet500: baseColors.violet.violet500,
      violet600: baseColors.violet.violet600,
      violet700: baseColors.violet.violet700,
      violet800: baseColors.violet.violet800,
      violet900: baseColors.violet.violet900
    },
    typography: {
      typo1: "#312049",
      typo2: "#4a3a61",
      white: "#ffffff",
      typo3: "#4b4554",
      typo4: "#796f87",
      typo5: "#b4acbf"
    },
    border: {
      border1: "#f9f7fd",
      border2: "#e3e1e5",
      border3: "rgba(249, 247, 253, 0.5000)",
      accent: baseColors.violet.violet500,
      accent2: baseColors.aqua.aqua500
    },
    accentPrimary: baseColors.violet.violet500,
    accent: {
      primary: baseColors.violet.violet500,
      accent600: baseColors.violet.violet600,
      accent400: baseColors.violet.violet400,
      accent200: baseColors.violet.violet200,
      accent100: baseColors.violet.violet100,
      primaryEmphasis: "#e9e5ff",
      secondary: baseColors.aqua.aqua500,
      secondaryEmphasis: "#cdfef2"
    },
    sematicWarning: "#F5C919",
    dropShadow: {
      dropShadow100: baseColors.dropShadow.dropShadow100,
      dropShadow200: baseColors.dropShadow.dropShadow200,
      dropShadow300: baseColors.dropShadow.dropShadow300,
      dropShadow400: baseColors.dropShadow.dropShadow400
    },
    earnWizard: {
      mask: "#9389EF",
      border: "#D7B9FF"
    },
    donutShape: {
      mask: "#9389EF"
    },
    promoted: {
      start: baseColors.violet.violet500,
      stop: "#f9f7fd"
    }
  }
};



exports.baseColors = baseColors; exports.colors = colors;

"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../../components/svgicon');
var _material = require('@mui/material');
const GRADIENT_COLOR = {
  light: {
    start: "#791AFF",
    end: "#4A00B2"
  },
  dark: {
    start: "#07DFAA",
    end: "#049571"
  }
};
function TierMedalStarIcon(_ref) {
  let {
    size,
    ...props
  } = _ref;
  const {
    palette
  } = _material.useTheme.call(void 0, );
  const {
    start,
    end
  } = GRADIENT_COLOR[palette.mode];
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 25 24",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("g", {
    id: "vuesax/outline/medal-star"
  }, /* @__PURE__ */ _react2.default.createElement("g", {
    id: "medal-star"
  }, /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector",
    d: "M12.75 16.75C12.35 16.75 11.95 16.72 11.57 16.65C9.45 16.34 7.52 15.12 6.3 13.31C5.45 12.03 5 10.54 5 9C5 4.73 8.48 1.25 12.75 1.25C17.02 1.25 20.5 4.73 20.5 9C20.5 10.54 20.05 12.03 19.2 13.31C17.97 15.13 16.04 16.34 13.9 16.66C13.55 16.72 13.15 16.75 12.75 16.75ZM12.75 2.75C9.3 2.75 6.5 5.55 6.5 9C6.5 10.25 6.86 11.45 7.54 12.47C8.53 13.93 10.08 14.91 11.8 15.16C12.44 15.27 13.07 15.27 13.66 15.16C15.41 14.91 16.96 13.92 17.95 12.46C18.63 11.44 18.99 10.24 18.99 8.98999C19 5.54999 16.2 2.75 12.75 2.75Z",
    fill: "url(#paint0_linear_2788_58396)"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_2",
    d: "M7.21982 22.588C7.07982 22.588 6.94982 22.568 6.80982 22.538C6.15982 22.388 5.65982 21.888 5.50982 21.238L5.15982 19.768C5.13982 19.678 5.06982 19.608 4.96982 19.578L3.31982 19.188C2.69982 19.038 2.20982 18.578 2.03982 17.968C1.86982 17.358 2.03982 16.698 2.48982 16.248L6.38982 12.348C6.54982 12.188 6.76982 12.108 6.98982 12.128C7.20982 12.148 7.40982 12.268 7.53982 12.458C8.52982 13.918 10.0798 14.908 11.8098 15.158C12.4498 15.268 13.0798 15.268 13.6698 15.158C15.4198 14.908 16.9698 13.918 17.9598 12.458C18.0798 12.268 18.2898 12.148 18.5098 12.128C18.7298 12.108 18.9498 12.188 19.1098 12.348L23.0098 16.248C23.4598 16.698 23.6298 17.358 23.4598 17.968C23.2898 18.578 22.7898 19.048 22.1798 19.188L20.5298 19.578C20.4398 19.598 20.3698 19.668 20.3398 19.768L19.9898 21.238C19.8398 21.888 19.3398 22.388 18.6898 22.538C18.0398 22.698 17.3698 22.468 16.9498 21.958L12.7498 17.128L8.54982 21.968C8.20982 22.368 7.72982 22.588 7.21982 22.588ZM6.83982 14.028L3.54982 17.318C3.45982 17.408 3.46982 17.508 3.48982 17.568C3.49982 17.618 3.54982 17.718 3.66982 17.738L5.31982 18.128C5.96982 18.278 6.46982 18.778 6.61982 19.428L6.96982 20.898C6.99982 21.028 7.09982 21.068 7.15982 21.088C7.21982 21.098 7.31982 21.108 7.40982 21.008L11.2398 16.598C9.53982 16.268 7.97982 15.358 6.83982 14.028ZM14.2598 16.588L18.0898 20.988C18.1798 21.098 18.2898 21.098 18.3498 21.078C18.4098 21.068 18.4998 21.018 18.5398 20.888L18.8898 19.418C19.0398 18.768 19.5398 18.268 20.1898 18.118L21.8398 17.728C21.9598 17.698 22.0098 17.608 22.0198 17.558C22.0398 17.508 22.0498 17.398 21.9598 17.308L18.6698 14.018C17.5198 15.348 15.9698 16.258 14.2598 16.588Z",
    fill: "url(#paint1_linear_2788_58396)"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    id: "Vector_3",
    d: "M14.6401 12.8883C14.3801 12.8883 14.0701 12.8183 13.7001 12.5983L12.7501 12.0283L11.8001 12.5883C10.9301 13.1083 10.3601 12.8083 10.1501 12.6583C9.94014 12.5083 9.49014 12.0583 9.72014 11.0683L9.96014 10.0383L9.16014 9.29828C8.72014 8.85828 8.56014 8.3283 8.71014 7.8483C8.86014 7.3683 9.30014 7.02828 9.92014 6.92828L10.9901 6.74829L11.5001 5.6283C11.7901 5.0583 12.2401 4.73828 12.7501 4.73828C13.2601 4.73828 13.7201 5.06831 14.0001 5.63831L14.5901 6.8183L15.5801 6.93829C16.1901 7.03829 16.6301 7.37828 16.7901 7.85828C16.9401 8.33828 16.7801 8.86829 16.3401 9.30829L15.5101 10.1383L15.7701 11.0683C16.0001 12.0583 15.5501 12.5083 15.3401 12.6583C15.2301 12.7483 14.9901 12.8883 14.6401 12.8883ZM10.3601 8.38831L11.0501 9.07828C11.3701 9.39828 11.5301 9.9383 11.4301 10.3783L11.2401 11.1783L12.0401 10.7083C12.4701 10.4583 13.0501 10.4583 13.4701 10.7083L14.2701 11.1783L14.0901 10.3783C13.9901 9.9283 14.1401 9.39828 14.4601 9.07828L15.1501 8.38831L14.2801 8.23828C13.8601 8.16828 13.4401 7.8583 13.2501 7.4783L12.7501 6.49829L12.2501 7.49829C12.0701 7.86829 11.6501 8.1883 11.2301 8.2583L10.3601 8.38831Z",
    fill: "url(#paint2_linear_2788_58396)"
  }))), /* @__PURE__ */ _react2.default.createElement("defs", null, /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint0_linear_2788_58396",
    x1: "8.86132",
    y1: "9",
    x2: "18.3838",
    y2: "9",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: start
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: end
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint1_linear_2788_58396",
    x1: "7.34343",
    y1: "17.3566",
    x2: "20.5824",
    y2: "17.3566",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: start
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: end
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint2_linear_2788_58396",
    x1: "10.6955",
    y1: "8.81329",
    x2: "15.7269",
    y2: "8.81329",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: start
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: end
  }))));
}


exports.default = TierMedalStarIcon;

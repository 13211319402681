"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }function _extends() {
  return _extends = Object.assign ? Object.assign.bind() : function(n) {
    for (var e = 1; e < arguments.length; e++) {
      var t = arguments[e];
      for (var r in t)
        ({}).hasOwnProperty.call(t, r) && (n[r] = t[r]);
    }
    return n;
  }, _extends.apply(null, arguments);
}
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _svgicon = require('../../../components/svgicon');
var _styledcomponents = require('styled-components');
function TierLevel4BigLight(_ref) {
  let {
    size,
    ...props
  } = _ref;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 58 76",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("g", {
    filter: "url(#filter0_dddd_14115_6725)"
  }, /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M-0.162598 8.9574C-0.162598 7.1564 1.04101 5.57749 2.77765 5.10034L7.12806 3.90503L28.297 0.125522C28.762 0.0424971 29.2381 0.0424969 29.7031 0.125521L50.872 3.90503L55.2225 5.10034C56.9591 5.57749 58.1627 7.1564 58.1627 8.9574V65.4207C58.1627 67.253 56.9178 68.851 55.1412 69.2992L51.9253 70.1105C51.2356 70.2845 50.6048 70.6392 50.0979 71.1382L46.7034 74.4791C45.7395 75.4278 44.3615 75.8252 43.0404 75.5354L36.2907 74.0548L30.044 72.3659C29.3603 72.1811 28.6398 72.1811 27.9561 72.3659L21.7094 74.0548L15.5301 75.4514C14.2977 75.73 13.0062 75.4099 12.0465 74.5881L7.84112 70.9868C7.37212 70.5852 6.81651 70.2976 6.21781 70.1465L2.85894 69.2992C1.08233 68.851 -0.162598 67.253 -0.162598 65.4207V8.9574Z",
    fill: "url(#paint0_radial_14115_6725)"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M0.74188 8.9574C0.74188 7.56364 1.67333 6.34175 3.01728 5.97249L7.32778 4.78815L28.456 1.01592C28.8158 0.951668 29.1843 0.951668 29.5441 1.01592L50.6723 4.78815L54.9828 5.97249C56.3268 6.34175 57.2582 7.56364 57.2582 8.9574V65.4207C57.2582 66.8386 56.2948 68.0753 54.9199 68.4222L51.704 69.2335C50.8584 69.4468 50.085 69.8818 49.4634 70.4935L46.0689 73.8345C45.323 74.5687 44.2566 74.8762 43.2342 74.652L36.5059 73.176L30.2801 71.4928C29.4418 71.2661 28.5583 71.2661 27.72 71.4928L21.4915 73.1768L15.3307 74.5692C14.377 74.7848 13.3775 74.5371 12.6348 73.9011L8.42943 70.2998C7.85439 69.8074 7.17314 69.4547 6.43905 69.2695L3.08019 68.4222C1.70531 68.0753 0.74188 66.8386 0.74188 65.4207V8.9574Z",
    stroke: "#791AFF",
    strokeWidth: "1.80895"
  })), /* @__PURE__ */ _react2.default.createElement("g", {
    filter: "url(#filter1_dddd_14115_6725)"
  }, /* @__PURE__ */ _react2.default.createElement("rect", {
    x: "7",
    y: "12",
    width: "43.5",
    height: "52.4193",
    rx: "2.63834",
    fill: "url(#paint1_linear_14115_6725)"
  }), /* @__PURE__ */ _react2.default.createElement("rect", {
    x: "7.25",
    y: "12.25",
    width: "43",
    height: "51.9193",
    rx: "2.38834",
    stroke: "url(#paint2_linear_14115_6725)",
    strokeWidth: "0.5"
  }), /* @__PURE__ */ _react2.default.createElement("g", {
    filter: "url(#filter2_dddd_14115_6725)"
  }, /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.3871 47.7164V42.9868L29.2459 24.3041H33.3236V30.8507H30.9103L23.4344 42.6817V42.9036H40.2863V47.7164H17.3871ZM31.0212 52.7096V46.274L31.1322 44.1796V24.3041H36.7633V52.7096H31.0212Z",
    fill: "url(#paint3_linear_14115_6725)"
  }))), /* @__PURE__ */ _react2.default.createElement("defs", null, /* @__PURE__ */ _react2.default.createElement("filter", {
    id: "filter0_dddd_14115_6725",
    x: "-14.5522",
    y: "-3.02026",
    width: "87.1044",
    height: "129.012",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ _react2.default.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "2.05566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "2.56957"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.08 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "9.25047"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "4.62523"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.07 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect1_dropShadow_14115_6725",
    result: "effect2_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "20.5566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "6.16698"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.04 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect2_dropShadow_14115_6725",
    result: "effect3_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "35.974"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "7.19481"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.01 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect3_dropShadow_14115_6725",
    result: "effect4_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect4_dropShadow_14115_6725",
    result: "shape"
  })), /* @__PURE__ */ _react2.default.createElement("filter", {
    id: "filter1_dddd_14115_6725",
    x: "-7.38961",
    y: "8.91651",
    width: "72.2792",
    height: "105.866",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ _react2.default.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "2.05566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "2.56957"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.08 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "9.25047"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "4.62523"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.07 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect1_dropShadow_14115_6725",
    result: "effect2_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "20.5566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "6.16698"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.04 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect2_dropShadow_14115_6725",
    result: "effect3_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "35.974"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "7.19481"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.01 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect3_dropShadow_14115_6725",
    result: "effect4_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect4_dropShadow_14115_6725",
    result: "shape"
  })), /* @__PURE__ */ _react2.default.createElement("filter", {
    id: "filter2_dddd_14115_6725",
    x: "12.8648",
    y: "23.3996",
    width: "31.9439",
    height: "45.5906",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ _react2.default.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "0.904477"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "0.904477"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.05 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "2.71343"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "1.35672"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.04 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect1_dropShadow_14115_6725",
    result: "effect2_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "6.33134"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "1.80895"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.03 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect2_dropShadow_14115_6725",
    result: "effect3_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "11.7582"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "2.26119"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.01 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect3_dropShadow_14115_6725",
    result: "effect4_dropShadow_14115_6725"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect4_dropShadow_14115_6725",
    result: "shape"
  })), /* @__PURE__ */ _react2.default.createElement("radialGradient", {
    id: "paint0_radial_14115_6725",
    cx: "0",
    cy: "0",
    r: "1",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "translate(30.3374 75.8219) rotate(-90) scale(77.9258 144.469)"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "#30263E"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "0.498523",
    stopColor: "#6525BF"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: "#30263E"
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint1_linear_14115_6725",
    x1: "30.6839",
    y1: "12",
    x2: "30.684",
    y2: "64.4193",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "#3E344D",
    stopOpacity: "0.8"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "0.527557",
    stopColor: "#552599",
    stopOpacity: "0.8"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopOpacity: "0.8"
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint2_linear_14115_6725",
    x1: "27.8829",
    y1: "11.7154",
    x2: "11.5466",
    y2: "8.89488",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "#4D2584"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: "#8F78AD"
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint3_linear_14115_6725",
    x1: "33.2178",
    y1: "26.3209",
    x2: "33.2178",
    y2: "52.9818",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "white"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: "#9D9D9D"
  }))));
}
function TierLevel4BigDark(_ref2) {
  let {
    size,
    ...props
  } = _ref2;
  return /* @__PURE__ */ _react2.default.createElement(_svgicon.CustomSvgIcon, _extends({
    viewBox: "0 0 58 76",
    style: size ? {
      fontSize: size
    } : {}
  }, props), /* @__PURE__ */ _react2.default.createElement("g", {
    filter: "url(#filter0_dddd_14115_6729)"
  }, /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M-0.162598 8.9574C-0.162598 7.1564 1.04101 5.57749 2.77765 5.10034L7.12806 3.90503L28.297 0.125522C28.762 0.0424971 29.2381 0.0424969 29.7031 0.125521L50.872 3.90503L55.2225 5.10034C56.9591 5.57749 58.1627 7.1564 58.1627 8.9574V65.4207C58.1627 67.253 56.9178 68.851 55.1412 69.2992L51.9253 70.1105C51.2356 70.2845 50.6048 70.6392 50.0979 71.1382L46.7034 74.4791C45.7395 75.4278 44.3615 75.8252 43.0404 75.5354L36.2907 74.0548L30.044 72.3659C29.3603 72.1811 28.6398 72.1811 27.9561 72.3659L21.7094 74.0548L15.5301 75.4514C14.2977 75.73 13.0062 75.4099 12.0465 74.5881L7.84112 70.9868C7.37212 70.5852 6.81651 70.2976 6.21781 70.1465L2.85894 69.2992C1.08233 68.851 -0.162598 67.253 -0.162598 65.4207V8.9574Z",
    fill: "url(#paint0_radial_14115_6729)"
  }), /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M0.74188 8.9574C0.74188 7.56364 1.67333 6.34175 3.01728 5.97249L7.32778 4.78815L28.456 1.01592C28.8158 0.951668 29.1843 0.951668 29.5441 1.01592L50.6723 4.78815L54.9828 5.97249C56.3268 6.34175 57.2582 7.56364 57.2582 8.9574V65.4207C57.2582 66.8386 56.2948 68.0753 54.9199 68.4222L51.704 69.2335C50.8584 69.4468 50.085 69.8818 49.4634 70.4935L46.0689 73.8345C45.323 74.5687 44.2566 74.8762 43.2342 74.652L36.5059 73.176L30.2801 71.4928C29.4418 71.2661 28.5583 71.2661 27.72 71.4928L21.4915 73.1768L15.3307 74.5692C14.377 74.7848 13.3775 74.5371 12.6348 73.9011L8.42943 70.2998C7.85439 69.8074 7.17314 69.4547 6.43905 69.2695L3.08019 68.4222C1.70531 68.0753 0.74188 66.8386 0.74188 65.4207V8.9574Z",
    stroke: "#07F8BD",
    strokeWidth: "1.80895"
  })), /* @__PURE__ */ _react2.default.createElement("g", {
    filter: "url(#filter1_dddd_14115_6729)"
  }, /* @__PURE__ */ _react2.default.createElement("rect", {
    x: "7",
    y: "12",
    width: "43.5",
    height: "52.4193",
    rx: "2.63834",
    fill: "url(#paint1_linear_14115_6729)"
  }), /* @__PURE__ */ _react2.default.createElement("rect", {
    x: "7.25",
    y: "12.25",
    width: "43",
    height: "51.9193",
    rx: "2.38834",
    stroke: "url(#paint2_linear_14115_6729)",
    strokeWidth: "0.5"
  }), /* @__PURE__ */ _react2.default.createElement("g", {
    filter: "url(#filter2_dddd_14115_6729)"
  }, /* @__PURE__ */ _react2.default.createElement("path", {
    d: "M17.3871 47.7164V42.9868L29.2459 24.3041H33.3236V30.8507H30.9103L23.4344 42.6817V42.9036H40.2863V47.7164H17.3871ZM31.0212 52.7096V46.274L31.1322 44.1796V24.3041H36.7633V52.7096H31.0212Z",
    fill: "url(#paint3_linear_14115_6729)"
  }))), /* @__PURE__ */ _react2.default.createElement("defs", null, /* @__PURE__ */ _react2.default.createElement("filter", {
    id: "filter0_dddd_14115_6729",
    x: "-14.5522",
    y: "-3.02026",
    width: "87.1044",
    height: "129.012",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ _react2.default.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "2.05566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "2.56957"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.08 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "9.25047"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "4.62523"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.07 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect1_dropShadow_14115_6729",
    result: "effect2_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "20.5566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "6.16698"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.04 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect2_dropShadow_14115_6729",
    result: "effect3_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "35.974"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "7.19481"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.01 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect3_dropShadow_14115_6729",
    result: "effect4_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect4_dropShadow_14115_6729",
    result: "shape"
  })), /* @__PURE__ */ _react2.default.createElement("filter", {
    id: "filter1_dddd_14115_6729",
    x: "-7.38961",
    y: "8.91651",
    width: "72.2792",
    height: "105.866",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ _react2.default.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "2.05566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "2.56957"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.08 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "9.25047"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "4.62523"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.07 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect1_dropShadow_14115_6729",
    result: "effect2_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "20.5566"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "6.16698"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.04 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect2_dropShadow_14115_6729",
    result: "effect3_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "35.974"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "7.19481"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.01 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect3_dropShadow_14115_6729",
    result: "effect4_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect4_dropShadow_14115_6729",
    result: "shape"
  })), /* @__PURE__ */ _react2.default.createElement("filter", {
    id: "filter2_dddd_14115_6729",
    x: "12.8648",
    y: "23.3996",
    width: "31.9439",
    height: "45.5906",
    filterUnits: "userSpaceOnUse",
    colorInterpolationFilters: "sRGB"
  }, /* @__PURE__ */ _react2.default.createElement("feFlood", {
    floodOpacity: "0",
    result: "BackgroundImageFix"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "0.904477"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "0.904477"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.05 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "BackgroundImageFix",
    result: "effect1_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "2.71343"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "1.35672"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.04 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect1_dropShadow_14115_6729",
    result: "effect2_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "6.33134"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "1.80895"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.03 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect2_dropShadow_14115_6729",
    result: "effect3_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    in: "SourceAlpha",
    type: "matrix",
    values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
    result: "hardAlpha"
  }), /* @__PURE__ */ _react2.default.createElement("feOffset", {
    dy: "11.7582"
  }), /* @__PURE__ */ _react2.default.createElement("feGaussianBlur", {
    stdDeviation: "2.26119"
  }), /* @__PURE__ */ _react2.default.createElement("feColorMatrix", {
    type: "matrix",
    values: "0 0 0 0 0.176471 0 0 0 0 0.0470588 0 0 0 0 0.372549 0 0 0 0.01 0"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in2: "effect3_dropShadow_14115_6729",
    result: "effect4_dropShadow_14115_6729"
  }), /* @__PURE__ */ _react2.default.createElement("feBlend", {
    mode: "normal",
    in: "SourceGraphic",
    in2: "effect4_dropShadow_14115_6729",
    result: "shape"
  })), /* @__PURE__ */ _react2.default.createElement("radialGradient", {
    id: "paint0_radial_14115_6729",
    cx: "0",
    cy: "0",
    r: "1",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "translate(30.3374 75.8219) rotate(-90) scale(77.9258 144.469)"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "#011913"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "0.498523",
    stopColor: "#07DFAA"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: "#011913"
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint1_linear_14115_6729",
    x1: "30.6839",
    y1: "12",
    x2: "30.684",
    y2: "64.4193",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "#011913",
    stopOpacity: "0.8"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "0.527557",
    stopColor: "#024A39",
    stopOpacity: "0.8"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopOpacity: "0.8"
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint2_linear_14115_6729",
    x1: "27.8829",
    y1: "11.7154",
    x2: "11.5466",
    y2: "8.89488",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "#024A39"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: "#78AD9B"
  })), /* @__PURE__ */ _react2.default.createElement("linearGradient", {
    id: "paint3_linear_14115_6729",
    x1: "33.2178",
    y1: "26.3209",
    x2: "33.2178",
    y2: "52.9818",
    gradientUnits: "userSpaceOnUse"
  }, /* @__PURE__ */ _react2.default.createElement("stop", {
    stopColor: "white"
  }), /* @__PURE__ */ _react2.default.createElement("stop", {
    offset: "1",
    stopColor: "#9D9D9D"
  }))));
}
function TierLevel4Big(_ref3) {
  let {
    size,
    ...props
  } = _ref3;
  const {
    palette: {
      mode
    }
  } = _styledcomponents.useTheme.call(void 0, );
  return mode === "light" ? /* @__PURE__ */ _react2.default.createElement(TierLevel4BigLight, _extends({
    size
  }, props)) : /* @__PURE__ */ _react2.default.createElement(TierLevel4BigDark, _extends({
    size
  }, props));
}




exports.TierLevel4BigDark = TierLevel4BigDark; exports.TierLevel4BigLight = TierLevel4BigLight; exports.default = TierLevel4Big;

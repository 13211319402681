"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _isUndefined = require('lodash/isUndefined'); var _isUndefined2 = _interopRequireDefault(_isUndefined);
var _containerbox = require('../container-box');
var _button = require('../button');
var _styledcomponents = require('styled-components'); var _styledcomponents2 = _interopRequireDefault(_styledcomponents);
var _theme = require('../../theme');
var _material = require('@mui/material');
var _reactintl = require('react-intl');
var _icons = require('../../icons');
const TierPillTab = _styledcomponents2.default.call(void 0, _button.Button).attrs({
  variant: "outlined",
  size: "small",
  color: "info"
})`
  ${(_ref) => {
  let {
    selected,
    theme: {
      palette: {
        mode
      },
      spacing
    }
  } = _ref;
  return `
    padding: ${spacing(2)} !important;
    gap: ${spacing(2)};
    background-color: ${_theme.colors[mode].background.quartery};
    border: 1.5px solid ${_theme.colors[mode].border.border3};
    color: ${_theme.colors[mode].typography.typo4};
    ${selected && `
      background-color: ${_theme.colors[mode].background.tertiary};
      border: 1.5px solid ${_theme.colors[mode].border.border1};
      color: ${_theme.colors[mode].accent.primary};
      box-shadow: ${_theme.colors[mode].dropShadow.dropShadow100};
    `}
  `;
}}
`;
const FlatTiersIcons = {
  0: _icons.TierLevel0Flat,
  1: _icons.TierLevel1Flat,
  2: _icons.TierLevel2Flat,
  3: _icons.TierLevel3Flat,
  4: _icons.TierLevel4Flat
};
const ActiveTiersIcons = {
  0: _icons.TierLevel0Active,
  1: _icons.TierLevel1Active,
  2: _icons.TierLevel2Active,
  3: _icons.TierLevel3Active,
  4: _icons.TierLevel4Active
};
const TierPillIcon = (_ref2) => {
  let {
    tierLevel,
    isActive
  } = _ref2;
  const TierIcon = isActive ? ActiveTiersIcons[tierLevel] : FlatTiersIcons[tierLevel];
  return /* @__PURE__ */ _react2.default.createElement(TierIcon, {
    size: "1rem"
  });
};
const TierPillTabs = (_ref3) => {
  let {
    options,
    onChange,
    selected,
    disabled
  } = _ref3;
  const [uncontrolledSelection, setUncontrolledSelection] = _react.useState.call(void 0, _optionalChain([options, 'access', _ => _[0], 'optionalAccess', _2 => _2.key]));
  _react.useEffect.call(void 0, () => {
    if (_isUndefined2.default.call(void 0, uncontrolledSelection) && !_isUndefined2.default.call(void 0, options[0])) {
      setUncontrolledSelection(options[0].key);
    }
  }, [options]);
  const onSelect = (key) => {
    if (onChange) {
      onChange(key);
    }
    setUncontrolledSelection(key);
  };
  const selection = _isUndefined2.default.call(void 0, selected) ? uncontrolledSelection : selected;
  return /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
    gap: 2,
    flexWrap: "wrap"
  }, options.map((_ref4, index) => {
    let {
      key,
      label,
      isCurrent
    } = _ref4;
    return /* @__PURE__ */ _react2.default.createElement(TierPillTab, {
      key: key.toString(),
      onClick: () => onSelect(key),
      disabled,
      selected: key === selection
    }, /* @__PURE__ */ _react2.default.createElement(_containerbox.ContainerBox, {
      gap: 1,
      alignItems: "center"
    }, /* @__PURE__ */ _react2.default.createElement(TierPillIcon, {
      tierLevel: index,
      isActive: key === selection
    }), /* @__PURE__ */ _react2.default.createElement(_material.Typography, {
      variant: "bodySmallBold",
      color: (_ref5) => {
        let {
          palette: {
            mode
          }
        } = _ref5;
        return key === selection ? _theme.colors[mode].accent.primary : _theme.colors[mode].typography.typo4;
      }
    }, label), isCurrent && /* @__PURE__ */ _react2.default.createElement(_react2.default.Fragment, null, /* @__PURE__ */ _react2.default.createElement(_material.Typography, {
      variant: "bodySmallRegular",
      color: (_ref6) => {
        let {
          palette: {
            mode
          }
        } = _ref6;
        return _theme.colors[mode].accent.primary;
      }
    }, ` \xB7 `, /* @__PURE__ */ _react2.default.createElement(_reactintl.FormattedMessage, {
      id: "i2JQOo",
      defaultMessage: [{
        "type": 0,
        "value": "Current"
      }]
    })))));
  }));
};




exports.ActiveTiersIcons = ActiveTiersIcons; exports.FlatTiersIcons = FlatTiersIcons; exports.TierPillTabs = TierPillTabs;
